import React from 'react';
import diagrammetl from '../components/pictures/diagrammetl.png';


const MainContent = () => (
  <>
    <div className="container-fluid d-flex justify-content-center">
      <div className="h1-title">
        <h1>The perfect cross-listing tool for sellers all around the world</h1>
      </div>
    </div>
    <div className="container-fluid d-flex justify-content-center">
      <div className="h2-title">
        <h2>TradyList is designed for the retailers who want to expand their limits and reach new customers</h2>
      </div>
    </div>
    <div className="diagram-container d-flex justify-content-center">
      <img src={diagrammetl} className="diagrammetl" alt="Diagramme Presentation TradyList" />
    </div>

  </>
);

export default MainContent;
import React from 'react';

const Failure = () => {
  return (
    <div>
      <h1>Échec de l'authentification</h1>
      <p>Une erreur est survenue lors de l'authentification avec eBay.</p>
    </div>
  );
};

export default Failure;

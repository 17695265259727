import React from 'react';
import ReactDOM from 'react-dom/client'; // Required for React 18+
import App from './App'; // Import the main App component

// This is the root rendering function
const root = ReactDOM.createRoot(document.getElementById('root')); // Get the 'root' div from index.html
root.render(
  <React.StrictMode>
    <App /> {/* Render the main App component */}
  </React.StrictMode>
);

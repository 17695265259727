import React from 'react';

const Success = () => {
  return (
    <div>
      <h1>Authentification réussie !</h1>
      <p>Vous pouvez maintenant utiliser eBay avec votre compte.</p>
    </div>
  );
};

export default Success;

import React from 'react';

const Partner = () => (
    <div className="partner-container">
        <h3>Our Partners</h3>
    </div>   
        
);


export default Partner;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  // Import du Router
import Navbar from './components/Navbar';
import VideoSection from './components/VideoSection';
import MainContent from './components/MainContent';
import FeatureSection from './components/FeatureSection';
import Partner from './components/Partner';
import FreeButton from './components/FreeButton';
import PartnerLogo from './components/PartnerLogo';
import Success from './components/pages/Success';
import Failure from './components/pages/Failure';
import EbayLoginButton from './components/dev/EbayLoginButton';
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <Router>  {/* Enveloppement dans BrowserRouter */}
      <div className="App">
        <Navbar />
        <VideoSection />
        <MainContent />
        <FeatureSection />
        <FreeButton />
        <Partner />
        <PartnerLogo />
        <Routes>
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/" element={<EbayLoginButton />} />  {/* Utilise 'element' au lieu de 'component' */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import videoFile from '../components/videos/intro.mp4';


const VideoSection = () => (
  <div className="video-container">
    <div className="embed-responsive">
      <video autoPlay loop muted controls className="video-element">
        <source src={videoFile} type="video/mp4" />
      </video>
    </div>
  </div>
);

export default VideoSection;

import React from 'react';
import Ebaylogo from '../components/pictures/ebaylogo.png';
import Amazonlogo  from '../components/pictures/amazonlogo.png';
import Leboncoinlogo from '../components/pictures/leboncoinlogo.png';
import Vintedlogo from '../components/pictures/vintedlogo.png';
import Facebooklogo from '../components/pictures/facebooklogo.png';
import Voggtlogo from '../components/pictures/voggtlogo.png';
import Whatnotlogo from '../components/pictures/whatnotlogo.png';

const PartnerLogo = () => (
    <div className="Partnerlogo-container d-flex justify-content-center">
        <img src={Ebaylogo} className="ebaylogo" alt="Ebay Logo" />
        <img src={Amazonlogo} className="amazonlogo" alt="Amazon Logo" />
        <img src={Leboncoinlogo} className="leboncoinlogo" alt="Leboncoin Logo" />
        <img src={Vintedlogo} className="vintedlogo" alt="Vinted Logo" />
        <img src={Facebooklogo} className="facebooklogo" alt="Facebook Logo" />
        <img src={Voggtlogo} className="voggtlogo" alt="Voggt Logo" />
        <img src={Whatnotlogo} className="whatnotlogo" alt="Whatnot Logo" />
    </div>

);


export default PartnerLogo;
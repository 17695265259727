import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const NavbarButton = ({ label, onClick, className }) => {
  return (
    <button className={`btn nav-button ${className}`} onClick={onClick}>
      {label}
    </button>
  );
};

const Navbar = () => {
  const handleButtonClick = (page) => {
    console.log(`Navigating to ${page}`);
    // Ici, tu peux ajouter la logique pour rediriger ou gérer d'autres actions
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="#">TradyList</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item-1">
              <NavbarButton label="Features" className="nav-item-1" onClick={() => handleButtonClick('Features')} />
            </li>
            <li className="nav-item-1">
              <NavbarButton label="FAQs" className="nav-item-1" onClick={() => handleButtonClick('FAQs')} />
            </li>
            <li className="nav-item-1">
              <NavbarButton label="Pricings" className="nav-item-1" onClick={() => handleButtonClick('Pricings')} />
            </li>
            <li className="nav-item-1">
              <NavbarButton label="Contact" className="nav-item-1" onClick={() => handleButtonClick('Contact')} />
            </li>
          </ul>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavbarButton label="Login" className="login-btn" onClick={() => handleButtonClick('Login')} />
            </li>
            <li className="nav-item">
              <NavbarButton label="Sign In" className="signin-btn" onClick={() => handleButtonClick('Sign In')} />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from 'react';

const EbayLoginButton = () => {
  const handleLogin = () => {
    window.location.href = 'https://tradylist.com/api/auth/login';  // Redirige vers le backend pour l'auth
  };

  return (
    <button onClick={handleLogin}>
      Se connecter avec eBay
    </button>
  );
};

export default EbayLoginButton;

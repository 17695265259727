import React from 'react';
import pokemonImage from '../components/pictures/pokémon.png';
import divein from '../components/pictures/divein.png';

const FeatureSection = () => (
  <>
    <div className="whattl-container d-flex justify-content-center">
      <p>What's TradyList ?</p>
    </div>
    <div className="divein-container d-flex justify-content-center" style={{ width: '90%', margin: '0 auto 200px auto' }}>
      <div className="row align-items-center">
        <div className="col-md-6">
          <img src={divein} className="img-fluid rounded" alt="Habit livre jouets" />
        </div>
        <div className="col-md-6 text-content">
          <h3>Dive into Crosslisting World</h3>
          <p><span className="register">Register</span> your items on TradyList and <span className="selectall">select all</span> the marketplaces you want to share on.</p>
          <p>Win time to sell more efficiently.</p>
        </div>
      </div>
    </div>

    <div className="container my-5">
      <div className="row align-items-center">
        <div className="col-md-6 text-content">
          <h3>With Centralized Inventory</h3>
          <p>All your items are <span className="gathered">gathered</span> in the same place.</p>
          <p>Use tags to sort your articles as you want.</p>
          <p>List and <span className="delist">delist</span> in bulk in one click.</p>
        </div>
        <div className="col-md-6">
          <img src={pokemonImage} className="img-fluid rounded" alt="Hero Image" />
        </div>
      </div>
    </div>

    <div className="container my-5">
      <div className="row align-items-center">
        <div className="col-md-6">
          <img src={pokemonImage} className="img-fluid rounded" alt="Image description" />
        </div>
        <div className="col-md-6 text-content">
          <h3>Account Analytics</h3>
          <p>Summary tables of the completed sales.</p>
          <p>Separate and compare revenue of the different marketplaces.</p>
          <p>Sales projection and goals.</p>
        </div>
      </div>
    </div>

    <div className="container my-5">
      <div className="row align-items-center">
        <div className="col-md-6 text-content">
          <h3>And Unified Messaging App</h3>
          <p>Receive and answer to your customers' messages directly from our app.</p>
          <p>Simple as that.</p>
        </div>
        <div className="col-md-6">
          <img src={pokemonImage} className="img-fluid rounded" alt="Hero Image" />
        </div>
      </div>
    </div>
  </>
);

export default FeatureSection;
